$tabletWidth:550px;
$pcWidth:1100px;
$primary-color:rgba(43, 43, 43, 1);

@font-face {
  font-family: myComfortaa;
  src: url('../Comfortaa-VariableFont_wght.ttf');
}

html, body {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  overflow:hidden;
}

html * {
  font-family: myComfortaa;
}

header.main{
  box-shadow: 0 4px 4px rgba(0,0,0,0.6);
  position:fixed;
  top:0;
  left:0;
  background-color: $primary-color;
  width: 100vw;
  margin: 0;
  box-sizing: border-box;
  height: 40px;
  justify-content: space-between;
  color: white;
  @media (min-width: $tabletWidth) {
    height: 60px;
  }

  >div{
    height:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .banner-logo {
      height: 84%;
      margin:6px;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  .banner-links a {
    margin: 0 8px;
    font-size: 11px;

    @media (min-width: $tabletWidth) {
      margin: 0 12px;
      font-size: 14px;
    }
    @media (min-width: 750px) {
      margin: 0 14px;
      font-size: 14px;
    }

    @media (min-width: $pcWidth) {
      margin: 0 18px;
    }
  }
}

.main-content{
  position:absolute;
  width:100vw;
  height:100vh;
  overflow-y:auto;
  overflow-x:hidden;
  -webkit-overflow-scrolling: touch;
  top:40px;
  height:calc(100vh - 40px);
  @media (min-width: $tabletWidth) {
    top:60px;
    height:calc(100vh - 60px);
  }
}

#logo-text {
  margin-top:-300px;
  width:260px;
  @media (min-width: $tabletWidth) {
    width:500px;
    margin-top:-380px;
    margin-left:-40px
  }
  @media (min-width: $pcWidth) {
    width:700px;
    margin-top:-120px;
    margin-left:-260px
  }
}

section, .bg{
  position:relative;
  width:100vw;
  overflow-x:hidden;
  padding:98px 0;
  box-sizing:border-box;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  background-attachment:fixed;
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover;

  @media only screen and (max-device-width: 1024px){
    background-attachment: scroll;
  }
}

#section-1 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% 50%;
  height:100vh;
  @media (min-width: $tabletWidth) {
    background-position: 70% 50%;
  }
  @media (min-width: $pcWidth) {
    background-position: 0% 50%;
  }
}

aside{
  background-color:$primary-color;
  color:white;
  font-style: italic;
  padding:40px;
  display:flex;
  flex-direction:row;
  font-size:16px;
  align-items:center;
  justify-content: center;


  >div{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
    margin:0 28px;
  }
  img{
    height:56px;
    margin-right:14px;
  }
}

figure img{
  width:250px;
}
figcaption{
  line-height:30px;
  a{
    display:block;
    margin-top:40px;
    text-decoration: none;
    color:inherit;
  }
}

section{
  header, article{
    width:70%;
    max-width:1000px;
    background-color:rgba(43,43,43,0.86);
    color:#fff;
    padding:18px;
    margin:12px 0;
    opacity:1;
    transition:all 0.7s;
    h1{
      margin: 0;
      font-size:28px;
      font-weight:normal;
    }
    p{
      line-height:32px;
    }
  }
  header{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}


.columnRow{
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;

  @media screen and(min-width:740px){
    flex-direction:row;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row.right {
  justify-content: flex-end;
}

.row.left {
  justify-content: flex-start;
}

#logo-text {
  margin-top:-300px;
  width:260px;
  @media (min-width: $tabletWidth) {
    width:500px;
    margin-top:-380px;
    margin-left:-40px
  }
  @media (min-width: $pcWidth) {
    width:700px;
    margin-top:-120px;
    margin-left:-260px
  }
}

section, .bg{
  position:relative;
  width:100vw;
  overflow-x:hidden;
  padding:98px 0;
  box-sizing:border-box;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  background-attachment:fixed;
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover;

  @media only screen and (max-device-width: 1024px){
    background-attachment: scroll;
  }
}

#section-1 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% 50%;
  height:100vh;
  @media (min-width: $tabletWidth) {
    background-position: 70% 50%;
  }
  @media (min-width: $pcWidth) {
    background-position: 0% 50%;
  }
}

.bg-01{
  background-image: url('/img/hike-bg-01.jpg');
}

.bg-02 {
  background-image: url('/img/hike-bg-02.jpg');
}

.bg-03 {
  background-image: url('/img/hike-bg-03.jpg');
}

.bg-04{
  background-image: url('/img/hike-bg-04.jpg');
}

.bg-05{
  background-image: url('/img/hike-bg-05.jpg');
}

.bg-06{
  background-image: url('/img/hike-bg-06.jpg');
}

.bg-07{
  background-image: url('/img/hike-bg-07.jpg');
  background-position:right center;
}

.bg-08{
  background-image: url('/img/hike-bg-08.jpg');
  background-position:bottom center;
}

aside{
  background-color:$primary-color;
  color:white;
  font-style: italic;
  padding:40px;
  display:flex;
  flex-direction:row;
  font-size:16px;
  align-items:center;
  justify-content: center;

  >div{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
    margin:0 28px;
  }
  img{
    height:56px;
    margin-right:14px;
  }
}

figure img{
  width:250px;
}
figcaption{
  line-height:30px;
  a{
    display:block;
    margin-top:40px;
    text-decoration: none;
    color:inherit;
  }
}

section{
  header, article{
    width:70%;
    max-width:1000px;
    background-color:rgba(43,43,43,0.86);
    color:#fff;
    padding:18px;
    margin:12px 0;
    opacity:1;
    transition:all 0.7s;
    h1{
      margin: 0;
      font-size:28px;
      font-weight:normal;
    }
    p{
      line-height:32px;
    }
  }
  header{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

.h1-logo{
  height:32px;
  margin-right:8px;
}

footer{
  display:flex;
  align-items: center;
  justify-content: center;
  background-color:$primary-color;
  font-size:10px;
  height:26px;
  color:#fff;
}

.hideLeft{
  margin-right:180vw;
  opacity:0;
}

.hideRight{
  margin-left:180vw;
  opacity:0;
}

.columnRow{
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;

  @media screen and(min-width:740px){
    flex-direction:row;
  }
}

footer{
  display:flex;
  align-items: center;
  justify-content: center;
  background-color:$primary-color;
  font-size:10px;
  height:26px;
  color:#fff;
}
